import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SelectOne from "../ui/formElements/SelectOne";

const langOptions = [
    {
        value: "en",
        label: "EN"
    },
    {
        value: "pt",
        label: "PT"
    },
    {
        value: "es",
        label: "ES"
    },
    {
        value: "fr",
        label: "FR"
    },
    {
        value: "zh",
        label: "ZH"
    },
];

const LanguageSelect = () => {
    const { t, i18n } = useTranslation();

    console.log(i18n.resolvedLanguage)

    return (
        <SelectOne
            arrowType="carret"
            // defaultValue={langOptions[0]}
            isSearchable={false}
            onChange={(lang) => {
                console.log(lang)
                i18n.changeLanguage(lang.value)
            }}
            options={langOptions}
            value={i18n.resolvedLanguage}
            wrapperClasses="input--md header__select hidden xl-flex"
        />
    );
};

export default LanguageSelect;
