import React from "react";
import { useSelector } from "react-redux";
import { getDateRange } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";

const SeminarSingleDefaultAsside = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    const dateArray = getDateRange(pageData?.seminarDates)
        ? getDateRange(pageData?.seminarDates).split(",")
        : [];

    return (
        <div className="card-shadow px-36 py-32">
            <div className="flex items-center justify-between gap-12 mb-24 pb-24 border-bottom">
                <div className="price-group gap-4">
                    <span className="color-grey">
                        {t('from')} /

                        {" "}
                    </span>

                    <span className="price-group__price">
                        {pageData?.minimalPriceTicket}

                        $
                    </span>
                </div>

                <div className="group-ticket-info">
                    <div className="flex items-center gap-4 color-secondary">
                        <div className="flex">
                            <img src={require("../../../assets/img/icon-ticket.svg").default} alt="tickets" />
                        </div>
                        { pageData?.ticketsPurchased} &nbsp;
                    </div>

                    / {pageData?.maxGymCapacity}
                </div>
            </div>

            <div
                className={`flex items-center justify-between gap-12 mb-24 
                ${!user || user?.role === "student"
            ? ""
            : "pb-24 border-bottom"}`}
            >
                <span className="card-seminar__info-date card-seminar__info-date--lg">
                    {
                        dateArray && dateArray.length > 0 && (
                            <span className="card-seminar__info-date card-seminar__info-date--lg">
                                {dateArray[0]}

                                {", "}
                                &nbsp;
                                <span className="color-secondary">
                                    {dateArray[1]}
                                </span>
                            </span>
                        )}
                </span>
            </div>

            {(!user || user?.role === "student") ?? (
                <>
                    <button className="btn btn--primary btn--lg w-full gap-10 mb-8">
                        <span className="info fs-20">
                            Buy Ticket
                        </span>

                        <ChevronRignt />
                    </button>

                    <a
                        className="btn btn--default btn--lg fs-20 w-full mb-28"
                        href="#"
                    >
                        Book private lesson
                    </a>
                </>
            )}

            <button
                className="btn color-secondary gap-10 w-full mb-24 pb-24 border-bottom"
            >
                <ShareIcon />

                <span className="underline fw-500 not-italic tt-none">
                    {t('share')}
                </span>
            </button>

            <div className="ticket-info-body border-bottom mb-24 pb-24">
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('langs')}

                        {" "}
                    </span>

                    <span>
                        {pageData?.languages?.join(", ")}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('host')}
                        {" "}
                    </span>

                    <span>
                        Hart Jiu-Jitsu Conshohocken
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('address')}
                    </span>

                    <span>
                        {" "}

                        {
                            [Country.getCountryByCode(pageData?.location)?.name, pageData?.address].filter(Boolean).join(", ") || "Location not specified"
                        }
                    </span>
                </div>

            </div>

            <div className="row-group justify-between gap--sm">
                <span className="block color-text mb-12">
                    {t('attending')}
                </span>
            </div>

            <div className="flex items-center border-bottom mb-24 pb-24">
                <ul className="user-list flex-auto">
                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>

                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>

                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>
                </ul>

                <div className="flex flex-col">
                    <span className="fs-14 fw-500">
                        {pageData?.ticketsPurchased} /

                        {pageData?.maxGymCapacity}
                    </span>

                    <span className="fs-14 color-text">
                        {t('students')}
                    </span>
                </div>
            </div>

            <div className="ticket-info-body__item">
                <span className="color-text">
                    {t('min_attendance')}
                </span>

                <span className="flex items-center justify-between gap-4 flex-auto">
                    <div className="flex items-center gap-4">
                        <div className="ico ico-18">
                            <svg
                                fill="none"
                                height="18"
                                viewBox="0 0 18 18"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9 15C5.69175 15 3 12.3082 3 9C3 5.69175 5.69175 3 9 3C12.3082 3 15 5.69175 15 9C15 12.3082 12.3082 15 9 15Z"
                                    fill="#9747FF"
                                />

                                <path
                                    d="M7.50005 10.1902L5.7758 8.46898L4.7168 9.53098L7.50155 12.3097L12.531 7.28023L11.4705 6.21973L7.50005 10.1902Z"
                                    fill="#9747FF"
                                />
                            </svg>

                        </div>

                        <div>
                            {pageData?.minAttendance}

                            {" "}

                            {t('students')}
                        </div>
                    </div>

                    <div className="ico ico-18">
                        <svg
                            fill="none"
                            height="18"
                            viewBox="0 0 18 18"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path
                                    d="M9.24375 12.6562C9.24375 12.7909 9.13462 12.9 9 12.9C8.86538 12.9 8.75625 12.7909 8.75625 12.6562C8.75625 12.5216 8.86538 12.4125 9 12.4125C9.13462 12.4125 9.24375 12.5216 9.24375 12.6562Z"
                                    fill="#BDBDBD"
                                    stroke="#BDBDBD"
                                    strokeWidth="1.2"
                                />

                                <path
                                    d="M9 10.125V9.5625C10.2424 9.5625 11.25 8.68078 11.25 7.59375C11.25 6.50672 10.2424 5.625 9 5.625C7.75758 5.625 6.75 6.50672 6.75 7.59375V7.875"
                                    stroke="#BDBDBD"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.2"
                                />

                                <path
                                    d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
                                    stroke="#BDBDBD"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.2"
                                />
                            </g>

                            <defs>
                                <clipPath>
                                    <rect
                                        fill="white"
                                        height="18"
                                        width="18"
                                    />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </span>
            </div>

        </div>
    );
};

export default SeminarSingleDefaultAsside;
