import { addBusinessDays } from "date-fns";
import { defaultsDeep } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useSearchParams
} from "react-router-dom";
import api from "../../api";
import Button from "../../components/ui/buttons/Button";
import Loader from "../../components/ui/Loader";
import { getDateRange, getImageSrc, getTrainingStyle } from "../../helpers/utils";
import { getUser } from "../../redux/slices/applicationSlice";
import {
    acceptInstructorCampRequestFetch, rejectCampRequestFetch
} from "../../redux/thunks/planCampThuncks";
import {
    acceptInstructorRequestFetch, cancelSeminarRequestFetch
} from "../../redux/thunks/seminarsThunks";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar.svg";
import ReschedulePrivateLessonModal from "../../components/modals/ReschedulePrivateLessonModal";
import { ReactComponent as ChatIcon } from "../../assets/img/chatDark.svg";
import { acceptRequestsPrivateLessonsFetch, rejectRequestsPrivateLessonFetch } from "../../redux/thunks/privateLessonsThunks";
import classNames from "classnames";
import { PaginationTemplate } from "../../components/parts/PaginationTemplate";
import { useTabs } from "../../hooks/useTabs";

const RequestItem = ({ data, owner, getRequests }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const me = useSelector(getUser);
    const isSeminar = data.object_type === "seminar";
    const isCamp = data.object_type === "plancamp";

    const handleApprove = async () => {
        const requestInstructorId = data.id;

        if (!requestInstructorId) return;

        if (isSeminar) {
            const res = await dispatch(acceptInstructorRequestFetch({ requestInstructorId })).unwrap();
            const chatId = res?.data?.chatId

            if (chatId) {
                navigate(`/chat/${chatId}`)
            }

            getRequests();
        }

        if (isCamp) {
            const res = await dispatch(acceptInstructorCampRequestFetch({ instructorId: requestInstructorId })).unwrap();
            const chatId = res?.data?.chatId

            if (chatId) {
                navigate(`/chat/${chatId}`)
            }

            getRequests();
        }
    };

    const handleCancelRequest = async () => {
        const requestId = data.id;

        if (!requestId) return;

        if (isSeminar) {
            await dispatch(cancelSeminarRequestFetch({ requestId })).unwrap();
            getRequests();
        }

        if (isCamp) {
            await dispatch(rejectCampRequestFetch({ requestId })).unwrap();
            getRequests();
        }
    };

    const user = data.owner;
    const isMy = data?.owner.id === me?.id;
    const event = data.seminar || data.plancamp;
    const hrefPrefix = event?.userAcademy
        ? "planed-by-academy"
        : "planed-by-insctructor";

    return (
        <div className="request-item">
            <div className="request-item__user">
                <div className="ico ico--md rounded-full img-wrapper">
                    <img
                        alt={user?.fullName || user?.name}
                        src={getImageSrc(user?.photo)}
                    />
                </div>

                <div
                    className="col-group gap-4"
                    style={{ width: 300 }}
                >
                    <h6 className="heading truncate flex-auto">
                        {event?.name || event?.seminarName}
                    </h6>

                    <p className="color-text truncate flex-auto">
                        {event?.summary || event?.summary}
                    </p>
                </div>
                {!data?.isReaded && !isMy && <span className="badge badge--primary">New</span>}
            </div>

            <div className="request-item__details">
                <div className="col-group gap--xs">
                    <span className="label">
                        Price Range
                    </span>

                    <p className="fw-500 text--sm">

                        ${event?.seminarMinPrice}
                        {" - "}
                        ${event?.seminarMaxPrice}
                    </p>
                </div>

                {/* <div className="col-group gap--xs">
                    <span className="label">
                        Ticket Price
                    </span>

                    <p className="fw-500 text--sm">
                        {event?.ticketPrice}
                    </p>
                </div> */}

                <div className="col-group gap--xs">
                    <span className="label">
                        teaching
                    </span>

                    <p className="fw-500 text--sm">
                        {getTrainingStyle(event?.traningStyle)?.[0]?.title}
                    </p>
                </div>

                <div className="col-group gap--xs">
                    <span className="label">
                        Date
                    </span>

                    <p className="fw-500 text--sm">
                        {new Date(event.startAt || event.seminarDates?.[0]?.date).toLocaleDateString()}
                    </p>
                </div>
            </div>

            <div className="request-item__action">
                {/* <Button className="btn btn--sm btn--square notification" count={data.count}> */}
                {/* <Link to={`/chat/${data.chat?.id}`} className="btn btn--sm btn--square">
                    <span className="ico">
                        <ChatIcon />
                    </span>
                </Link> */}
                <Link
                    className="btn btn--sm"
                    to={`/${hrefPrefix}/${event?.id}`}
                >
                    Show Details
                </Link>

                {!isMy && (
                    <Button
                        className="btn btn--sm btn--primary"
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                )}

                <Button
                    className="btn btn--sm btn--default"
                    onClick={handleCancelRequest}
                >
                    Decline
                </Button>
            </div>
        </div>
    );
};

function LessonsRequestItem({ data, update }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const handleApprove = async (id) => {
        setIsApproving(true);
        const res = await dispatch(acceptRequestsPrivateLessonsFetch(data.id)).unwrap();

        if (res?.success) {
            navigate(`/chat/${res.data?.chat?.id}`);
            update && update();
        }

        setIsApproving(false);
    };

    const handleReject = async (id) => {
        setIsRejecting(true);
        const res = await dispatch(rejectRequestsPrivateLessonFetch(data.id)).unwrap();

        if (res?.success) {
            update && update();
        }

        setIsRejecting(false);
    };

    const studentProfile = data.student?.studentProfile;

    return (
        <>
            <div className="request-item">
                <div className="request-item__user">
                    <div className="ico ico--md img-wrapper rounded-full">
                        <img
                            alt="student"
                            src={getImageSrc(studentProfile?.photo)}
                        />
                    </div>

                    <div className="col-group gap-4">
                        <h6 className="heading">
                            {studentProfile?.fullName}
                        </h6>

                        <p className="color-text">
                            {getDateRange(data?.dates)}
                        </p>
                    </div>
                    {!data?.isReaded && <span className="badge badge--primary">New</span>}
                </div>

                <div className="request-item__details">
                    <div className="col-group gap--xs">
                        <span className="label">
                            Total Price
                        </span>

                        <p className="fw-500 text--sm">
                            ${data?.priceInHour}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            People
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.peopleAttending}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            teaching
                        </span>

                        <p className="fw-500 text--sm">
                            {getTrainingStyle(data?.style)?.[0]?.title}
                        </p>
                    </div>

                    <div className="col-group gap--xs">
                        <span className="label">
                            Location
                        </span>

                        <p className="fw-500 text--sm">
                            {data?.address}
                        </p>
                    </div>
                </div>

                <div className="request-item__action">
                    <button
                        className="btn btn--sm"
                        onClick={() => setOpenRescheduleModal(true)}
                        type="button"
                    >
                        <span className="ico">
                            <CalendarIcon />
                        </span>

                        RESCHEDULE
                    </button>

                    {/* <a href="#" className="btn btn--sm">Show Details</a>*/}

                    <button
                        className="btn btn--sm "
                        isLoading={isRejecting}
                        onClick={handleReject}
                    >
                        Decline
                    </button>

                    <Button
                        className="btn btn--sm btn--primary"
                        isLoading={isApproving}
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>

                    <Link
                        className="btn btn--sm btn--square notification"
                        count="1"
                        to={`/chat/${data?.chat?.id}`}
                    >
                        <span className="ico">
                            <ChatIcon />
                        </span>
                    </Link>
                </div>
            </div>

            <ReschedulePrivateLessonModal
                data={data.dates}
                handleClose={() => {
                    setOpenRescheduleModal(false);
                    update && update()
                }}
                id={data.id}
                open={openRescheduleModal}
            />
        </>
    );
}

export const InstructorsAndAcademiesRequests = ({ userRole }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useSelector(getUser)
    const [requests, setRequests] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);


    const [counts, setCounts] = useState({
        "upcomingSeminarsCount": 0,
        "upcomingPlancampsCount": 0,
        "plannedSeminars": 0,
        "plannedPlancamps": 0,
        "inProgressPlancampsCount": 0,
        "inProgressSeminarsCount": 0,
        "previousSeminars": 0,
        "previousPlancamps": 0,
        "newRequestsSeminarCount": 0,
        "newRequestsPlancampCount": 0,
        "seminarRequestsCount": 0,
        "plancampRequestsCount": 0,
        "countMyOwnEvents": 0,
        countMySeminarEvents: 0,
        countMyPlancampEvents: 0,
        countPrivateLessons: 0
    });

    const getCounts = async () => {
        const res = await api.requests.getCounts()
        if (res?.success) {
            setCounts(res.data)
        }
    }

    const tabs = [
        {
            label: "Seminar Requests",
            id: "seminars",
            count: counts.seminarRequestsCount
        },
        {
            label: "Camp Requests",
            id: "plancamp",
            count: counts.plancampRequestsCount
        },
        {
            label: "My Seminar Requests",
            id: "mySeminars",
            count: counts.countMySeminarEvents
        },
        {
            label: "My Camps Requests",
            id: "myCamps",
            count: counts.countMyPlancampEvents
        },
    ]

    const { activeTab, setActiveTab } = useTabs(tabs, "tab")

    const [lessonsRequests, setLessonsRequests] = useState([]);
    const [isLessonsRequestsLoading, setIsLessonsRequestsLoading] = useState(false);
    const [lessonsPagination, setLessonsPagination] = useState(null);

    const getLessonsRequestsRequests = async () => {
        setIsLessonsRequestsLoading(true);
        getCounts()
        const res = await api.requests.getRequestsPrivateLessons();

        setLessonsRequests(res?.data || []);

        setLessonsPagination(res?.pagination);
        setIsLessonsRequestsLoading(false);
    };


    const getRequests = async (tab) => {
        setIsLoading(true);
        const tabsMap = {
            seminars: `event=seminar`,
            plancamp: `event=plancamp`,
            mySeminars: "is_my_own=true&event=seminar",
            myCamps: "is_my_own=true&event=plancamp",
        }
        getCounts()
        const query = tabsMap[tab]
        const res = await api.requests.getRequests(query);
        if (res?.success) {
            setRequests(res.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const tab = searchParams.get("tab")
        if (tab) {
            getRequests(tab);
        }

        if (user?.role === "instructor" && tab === "lessons") {
            getLessonsRequestsRequests()
        }
    }, [searchParams]);

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="tab-seminars tabs">
            <div className="tabs__header flex items-center border-bottom gap-24 pb-24 pt-24 mb-24">
                <div className="tabs__nav row-group gap-24 flex-auto">
                    {
                        tabs.map(tab => (
                            <button
                                key={tab.id}
                                className={classNames("tab-link", { "active-tab": activeTab === tab.id })}
                                onClick={() => {
                                    setActiveTab(tab.id);
                                }}
                            >
                                <span className="tab-link__text">
                                    {tab.label}
                                </span>

                                <span className="tab-link__number">
                                    {tab.count}
                                </span>
                            </button>
                        ))
                    }
                    {
                        user?.role === "instructor" && (
                            <button
                                className={classNames("tab-link", { "active-tab": activeTab === "lessons" })}
                                onClick={() => {
                                    handleChangeTab("lessons");
                                }}
                            >
                                <span className="tab-link__text">
                                    Private Lesson Requests
                                </span>

                                <span className="tab-link__number">
                                    {counts?.countPrivateLessons}
                                </span>
                            </button>
                        )
                    }
                </div>
            </div>

            <div>
                {activeTab === "lessons" ? (
                    <div className="tab active-tab">
                        {
                            lessonsRequests?.length && !isLessonsRequestsLoading
                                ? (
                                    <div className="request-items mb-24">
                                        {lessonsRequests.map((item) => (
                                            <LessonsRequestItem
                                                key={item.id}
                                                data={item}
                                                update={getLessonsRequestsRequests}
                                            />
                                        ))}
                                    </div>
                                )

                                : isLessonsRequestsLoading
                                    ? <Loader />
                                    : (
                                        <h2>
                                            There is no requiests yet
                                        </h2>
                                    )
                        }
                        <PaginationTemplate {...lessonsPagination} />
                    </div>
                ) : (
                    <div className="tab active-tab">
                        {
                            requests?.length && !isLoading
                                ? (
                                    <div className="request-items mb-24">
                                        {requests.map((item) => (
                                            <RequestItem
                                                key={item.id}
                                                data={item}
                                                getRequests={getRequests}
                                            />
                                        ))}
                                    </div>
                                )

                                : isLoading
                                    ? <Loader />
                                    : (
                                        <h2>
                                            There is no requiests yet
                                        </h2>
                                    )
                        }
                        <PaginationTemplate {...pagination} />
                    </div>
                )}

            </div>
        </div>
    );
};
