import { useEffect } from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useParams, useSearchParams
} from "react-router-dom";
import PageLoader from "../../components/ui/PageLoader";
import { getSeminarDetailes } from "../../redux/slices/seminarsSlice";
import { getSeminarsById } from "../../redux/thunks/seminarsThunks";
import SeminarOverviewBought from "./parts/SeminarOverviewBought";
import SeminarOverviewDetail from "./parts/SeminarOverviewDetail";
import SeminarOverviewHeader from "./parts/SeminarOverviewHeader";
import SeminarOverviewPrices from "./parts/SeminarOverviewPrices";
import SeminarOverviewRequsts from "./parts/SeminarOverviewRequsts";

import { ReactComponent as ChevronPrevIcon } from "../../assets/img/ico-chevron--prev.svg";
import { getCampDetails } from "../../redux/slices/planCampSlice";
import { getSingleCampFetch } from "../../redux/thunks/planCampThuncks";

const SeminarOverview = ({ eventType = "seminar" }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const seminar = useSelector(getSeminarDetailes);
    const camp = useSelector(getCampDetails);
    const pageData = eventType === "seminar" ? seminar : camp;


    const { campDetails, isLoading: isCampsLoading } = useSelector((state) => state.planCamps);
    const { isLoading: isSeminarLoading } = useSelector((state) => state.seminars);
    console.log(campDetails, "campDetails");

    useEffect(() => {
        if (eventType === "camp") {
            dispatch(getSingleCampFetch(id));
        }
        if (eventType === "seminar") {
            dispatch(getSeminarsById(id));
        }
    }, [dispatch, id]);

    if (isCampsLoading || isSeminarLoading) return <PageLoader />;

    if (!pageData) {
        return null
    }

    return (
        <section className="section-settings profile-page">
            <div className="container container--full">
                <div className="section-heading-row">
                    <Link
                        className="btn btn-back"
                        to="/my-seminars"
                    >
                        <span className="ico">
                            <ChevronPrevIcon />
                        </span>

                        <p className="text-sm">
                            Back
                        </p>
                    </Link>
                </div>

                <div className="settings-row">
                    <div className="settings-col">
                        <div className="settings-body settings-gap">
                            <SeminarOverviewHeader pageData={pageData} eventType={pageData?._type} />
                            <div className="form form-settings">
                                {pageData?.status !== "pending" && <SeminarOverviewPrices data={pageData} eventType={pageData?._type} />}

                                <div className="form-body">
                                    <div className="form-items-list">
                                        <SeminarOverviewDetail pageData={pageData} eventType={pageData?._type} />
                                        <hr className="hr-horisontal my-42" />
                                        {pageData?.status === "pending"
                                            ? <SeminarOverviewRequsts eventType={pageData?._type} event={pageData} />
                                            : <SeminarOverviewBought eventType={pageData?._type} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SeminarOverview;
