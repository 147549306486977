import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import {
    Link, useLocation
} from "react-router-dom";
import {
    getDateRange, getImageSrc, getTrainingStyle
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import Button from "../../ui/buttons/Button";
import DropDown from "../../ui/DropDown";
import { ReactComponent as LocationIcon } from "../../../assets/img/icon-decor-city.svg";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const CardSeminar = ({ data = {}, actions, linkPrefix = "", isBooked }) => {
    if (!data) {
        return null
    }

    return (
        <div className="card-seminar">
            <CardImageBlock data={data} />

            <CardBody data={data} />

            <CardFooter
                actions={actions}
                data={data}
                linkPrefix={linkPrefix}
                isBooked={isBooked}
            />
        </div>
    );
};

function DatesRange({ data }) {
    const dateArray = data?.seminarDates
        ? getDateRange(data?.seminarDates)?.split(",") || []
        : getDateRange([data.startAt, data.endAt], true)?.split(",") || [];

    if (!dateArray?.length) return;

    return (
        <span className="card-seminar__info-date">
            {dateArray[0]}

            {", "}
            &nbsp;
            <span className="color-primary">
                {dateArray[1]}
            </span>
        </span>
    );
}

function CardImageBlock({ data }) {
    const trainingStyle = getTrainingStyle(data?.traningStyle);

    function getEventCover(event) {
        if (event?._type === 'seminar') return (event?.photos || []).find(ph => ph.id === event.coverId)?.url;

        if (event?._type === 'plancamp') return (event?.photos || []).find(ph => ph.isCover)?.url;

        return null;
    }

    return (
        <div className="card-seminar__img-block">
            <div className="card-seminar__img-info">
                <DatesRange data={data} />

                <span className="card-seminar__gi ml-auto">
                    {trainingStyle[0]?.icon}

                    <span>
                        {trainingStyle[0]?.title}
                    </span>
                </span>
            </div>

            <Link to={`/${data._type === 'seminar' ? 'seminars' : 'camps'}/${data.id}`}>
                <div className="card-seminar__img">
                    <img
                        alt="cover"
                        src={getImageSrc(data?.cover || getEventCover(data))}
                    />
                </div>
            </Link>

            {
                data.status === "pending" && (
                    <div className="card-seminar__label">
                        {"Seeking "}

                        {data?.user?.role === "academy"
                            ? "Instructor"
                            : "Academy"}
                    </div>
                )}
        </div>
    );
}

function CardBody({ data }) {
    const user = useSelector(getUser);

    const getLocation = (data) => {
        if (!data?.location) {
            return "Location not specified";
        }

        const location = data?.location
            ? Country.getCountryByCode(data?.country || data?.location)?.name
            : data?.country || data?.location;

        return `${location}, ${data?.address} `;
    };

    const academyProfile = data?.userAcademy?.academyProfile;
    const isPending = data.status === "pending";
    const isAcademyOwner = user?.role === "academy"

    return (
        <div className="card-seminar__body">
            <div className="card-seminar__group-city">
                <div className="ico">
                    <LocationIcon />
                </div>

                <span className="truncate">
                    {getLocation(data)}
                </span>
            </div>

            <h4 className="heading line-clamp-2">
                {data?.seminarName || data?.name}
            </h4>

            {academyProfile && (
                <div className="card-user-block mb-18">
                    <div className="ico ico--xs">
                        <img
                            alt="avatar"
                            src={getImageSrc(academyProfile?.photo)}
                        />
                    </div>

                    <div className="card-user-block__name">
                        {academyProfile?.name || "Name not specified"}
                    </div>
                </div>
            )}

            {!(isAcademyOwner && isPending) && !!data?.instructors?.length && (
                <div className="card-seminar__group-tags">
                    {data?.instructors?.map((item) => (
                        <span key={item.id}>
                            <Link
                                to={`/instructors/${item.user?.id}`}
                                className={"underline"}
                            >
                                {item.user?.instructorProfile?.fullName}
                            </Link>
                        </span>
                    ))}
                </div>
            )}

        </div>
    );
}

function Actions({ actions, data }) {
    return (
        <DropDown
            dropDownClasses="ml-auto card-actions"
            dropDownListClasses="dropdown-menu"
            labelButton={(
                <Button
                    className="btn btn--square btn--md radius"
                >
                    <DotsIcon />
                </Button>
            )}
        >
            <div className="dropdown-menu__body--item">
                {
                    actions.map(item => (
                        <Button
                            key={item.id}
                            className="btn btn--sm btn--thin active"
                            onClick={item.action}
                        >
                            {item.label}
                        </Button>
                    ))
                }
            </div>
        </DropDown>
    );
}

function CardFooter({ data, actions = [], linkPrefix, isBooked }) {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    const location = useLocation();
    const currentPath = location.pathname;
    const isPending = data.status === "pending";
    const isClient = user?.role !== "academy" && user?.role !== "instructor";
    const [basePath, id] = currentPath.split("/").filter(Boolean)
        .slice(-2);
    const newUrl = id && id !== "camps"
        ? currentPath.replace(id, data?.id)
        : data?.id?.toString();


    const getLink = () => {
        const isCamp = data?._type === "plancamp"

        if (linkPrefix) {
            return `${linkPrefix}/${data?.id}`
        }


        if (!isPending) {
            return `/${isCamp ? "camps" : "seminars"}/${data?.id}`
        }

        if (data?.user?.role === "instructor") {
            return `/planed-by-insctructor/${isCamp ? "camps/" : ""}${data?.id}`
        }

        if (data?.user?.role === "academy") {
            return `/planed-by-academy/${isCamp ? "camps/" : ""}${data?.id}`
        }
    }

    const ticketsLeft = (data?.maxGymCapacity || 0) - (data?.ticketsPurchased || 0)

    return (
        <div className="card-seminar__footer">
            {
                !isPending ? (
                    data?.minimalPriceTicket && (
                        <div className="price-group">
                            <span className="price-group__price">
                                ${data?.minimalPriceTicket}
                            </span>
                            <span className="color-grey mr-8">
                                / {t('ticket')}
                            </span>

                            <span className="price-group__amount">
                                {ticketsLeft} {t('left')}
                            </span>
                        </div>
                    )
                ) : (
                    <div className="price-group">
                        <span className="color-grey mr-8">
                            {t('from')}
                        </span>
                        {
                            data?.seminarMinPrice ? (
                                <span className="price-group__price">
                                    ${data?.seminarMinPrice}
                                </span>
                            ) : (
                                <span className="price-group__price">
                                    {data?.sharedProfit}%
                                </span>
                            )
                        }
                    </div>
                )
            }

            <div className="row-group gap--xs">
                {
                    (isClient && !isPending) && !isBooked
                        ? (
                            <Link
                                to={`/book/${data?._type === "plancamp" ? "camp/" : ""}${data.id}`}
                                className={classNames("btn btn--primary btn--md radius", {
                                    disabled: !ticketsLeft
                                })}
                                onClick={!ticketsLeft ? (e) => e.preventDefault() : null}
                            >
                                {t('book_now')}
                            </Link>
                        )


                        : (
                            <Link
                                to={getLink()}
                                className="btn btn--primary btn--md radius"
                            >
                                {t('view_seminar')}
                            </Link>
                        )


                }

                {!!actions?.length && (
                    <Actions
                        data={data}
                        actions={actions}
                    />
                )}
            </div>
        </div>
    );
}

export default CardSeminar;
