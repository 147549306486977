import { createSlice, current } from "@reduxjs/toolkit";
import {
    getAllChatsFetch, getChatByIdFetch, getChatMessagesFetch, sendChatMessageFetch, updateCurrentChatFetch
} from "../thunks/chatsThunks";
import {
    acceptInstructorRequestFetch, acceptSeminarRequestFetch, cancelSeminarRequestFetch, updateSeminarFetch
} from "../thunks/seminarsThunks";
const initialState = {
    chats: [],
    currentChatData: null,
    messages: [],
    pagination: {
        limit: null,
        maxPages: null,
        offset: null,
        page: null,
        total: null
    },
    unreadMessages: [],
    isLoading: false
};
const chatsSlice = createSlice({
    name: "chats",
    initialState,
    reducers: {
        setNewMessage: (state, action) => {
            if (action && action.payload.chatId === state.currentChatData?.id) {
                state.messages.push(action.payload);
            }
        },
        upChatToTop: (state, action) => {
            const message = action.payload;

            if (!message) return;

            const oldChats = JSON.parse(JSON.stringify(state.chats));
            const index = oldChats.findIndex((chat) => chat.id === message.chatId);
            const current = oldChats.splice(index, 1)[0];

            if (current) {
                current.updatedAt = message.createdAt;
                current.message = {
                    ...current.message,
                    id: message.id,
                    message: message.message,
                    userId: message.userId
                };
                state.chats = [current, ...oldChats];
            }
        },
        setUnreadMessages: (state, action) => {
            const arr = action.payload.map((el) => ({
                chatId: el.chatId,
                userId: el.userId,
                messageId: el.id
            }));

            state.unreadMessages = arr;
        },
        clearCurrentChatData: (state, action) => {
            state.currentChatData = null;
        },
        addUnreadMessage: (state, action) => {
            const oneEl = action.payload[0];

            if (state.currentChatData?.id !== oneEl.chatId) {
                state.unreadMessages.push(oneEl);
            }
        },
        clearUnreadMessages: (state, action) => {
            const readed = action.payload;
            const unread = JSON.parse(JSON.stringify(state.unreadMessages));
            const filtered = unread.filter((el) => !readed.includes(el.messageId));

            state.unreadMessages = filtered;
        },
        changeStatus: (state, action) => {
            // if(action.type === '')
            // state.currentChatData. 
            if (action.payload.eventType === "seminar" && state.currentChatData.seminar) {
                state.currentChatData.seminar.status = action.payload.status;
            }
        },
        updateChat: (state, action) => {
            if (state.currentChatData?.id === action.payload?.id) {
                state.currentChatData = {
                    ...state.currentChatData,
                    ...action.payload
                };
            }
        },
        updateAcademyAccept: (state, action) => {
            const chatId = action.payload?.academy?.chatId
            const academyAccept = action.payload?.academy;

            if (state.currentChatData?.id !== chatId) return

            if (current(state.currentChatData.academyAccept)) {
                state.currentChatData.academyAccept = {
                    ...state.currentChatData.academyAccept,
                    ...academyAccept
                };
                const index = state.currentChatData.academyAccept.accepts.findIndex(user => user.userId === action.payload.userId);

                if (index >= 0) {
                    state.currentChatData.academyAccept.accepts[index].steps = action.payload.steps
                } else {
                    state.currentChatData.academyAccept.accepts.push({
                        userId: action.payload.userId,
                        steps: action.payload.steps
                    })
                }
            } else {
                state.currentChatData.academyAccept = academyAccept
            }
        },
        updateChatPrivateLessonInvoice: (state, action) => {
            if (state.currentChatData?.privateLesson?.id === action?.payload?.productId && action?.payload?.productType === "private_lessons") {
                console.log("action.payload", action.payload);
                
                state.currentChatData.privateLesson = {
                    ...state.currentChatData.privateLesson,
                    invoice: action.payload
                }
            }
        },
        updateChatPrivateLesson: (state, action) => {
            if (state.currentChatData?.privateLesson?.id === action?.payload?.id) {
                state.currentChatData.privateLesson = {
                    ...state.currentChatData.privateLesson,
                    ...action.payload
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllChatsFetch.fulfilled, (state, { payload }) => {
                state.chats = payload?.data;
                state.pagination = payload?.pagination;
                state.isLoading = false;
            })
            .addCase(getAllChatsFetch.pending, (state, { payload }) => {
                state.isLoading = true;
            })
            .addCase(getAllChatsFetch.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(getChatMessagesFetch.fulfilled, (state, { payload }) => {
                state.messages = payload?.data.toSorted((a, b) => a.createdAt.localeCompare(b.createdAt));
                state.isMessagesLoading = false;
            })
            .addCase(getChatMessagesFetch.pending, (state, { payload }) => {
                state.isMessagesLoading = true;
            })
            .addCase(getChatMessagesFetch.rejected, (state, { payload }) => {
                state.isMessagesLoading = false;
            })
            .addCase(getChatByIdFetch.fulfilled, (state, { payload }) => {
                state.currentChatData = payload?.data;
                state.isCurrentChatDataLoading = false;
            })
            .addCase(updateCurrentChatFetch.fulfilled, (state, { payload }) => {
                state.currentChatData = payload?.data;
                state.isCurrentChatDataLoading = false;
            })
            .addCase(getChatByIdFetch.pending, (state, { payload }) => {
                state.isCurrentChatDataLoading = true;
            })
            .addCase(getChatByIdFetch.rejected, (state, { payload }) => {
                state.isCurrentChatDataLoading = false;
            })
            .addCase(sendChatMessageFetch.fulfilled, (state, { payload }) => {
                // state.messages.push(payload?.data)
                state.isMessageSending = false;
            })
            .addCase(sendChatMessageFetch.pending, (state, { payload }) => {
                state.isMessageSending = true;
            })
            .addCase(sendChatMessageFetch.rejected, (state, { payload }) => {
                state.isMessageSending = false;
            })
            // .addCase(acceptSeminarRequestFetch.fulfilled, (state, { payload }) => {
            //     if (state?.currentChatData?.academyAccept?.step) {
            //         state.currentChatData.academyAccept.step = payload?.data?.step;
            //     }
            //     state.isCurrentChatDataLoading = false;
            // })
            // .addCase(acceptSeminarRequestFetch.pending, (state, { payload }) => {
            //     state.isCurrentChatDataLoading = true;
            // })
            // .addCase(acceptSeminarRequestFetch.rejected, (state, { payload }) => {
            //     state.isCurrentChatDataLoading = false;
            // })
            .addCase(acceptInstructorRequestFetch.fulfilled, (state, { payload }) => {
                // if (state?.currentChatData?.academyAccept?.step) {
                //     state.currentChatData.academyAccept.step = "details"
                // } else {
                //     state.currentChatData.academyAccept = { step: "details" }
                // } 
                state.isCurrentChatDataLoading = false;
            })
            .addCase(acceptInstructorRequestFetch.pending, (state, { payload }) => {
                state.isCurrentChatDataLoading = true;
            })
            .addCase(acceptInstructorRequestFetch.rejected, (state, { payload }) => {
                state.isCurrentChatDataLoading = false;
            })
            .addCase(cancelSeminarRequestFetch.fulfilled, (state, { payload }) => {
                state.currentChatData.academyAccept = null;
                state.isCurrentChatDataLoading = false;
            })
            .addCase(cancelSeminarRequestFetch.pending, (state, { payload }) => {
                state.isCurrentChatDataLoading = true;
            })
            .addCase(cancelSeminarRequestFetch.rejected, (state, { payload }) => {
                state.isCurrentChatDataLoading = false;
            })
        // .addCase(updateSeminarFetch.fulfilled, (state, { payload }) => {
        //     state.isLoading = false;
        //     if(state.currentChatData?.seminar) {
        //         state.currentChatData.seminar = {...current(state.currentChatData.seminar), ...payload};
        //     } else {
        //         state.currentChatData = {
        //             ...current(state.currentChatData),
        //             seminar: payload
        //         };
        //     }
        // })
    }
});

export const {
    setNewMessage,
    upChatToTop,
    clearCurrentChatData,
    setUnreadMessages,
    addUnreadMessage,
    clearUnreadMessages,
    changeStatus,
    updateChat,
    updateChatPrivateLesson,
    updateAcademyAccept,
    updateChatPrivateLessonInvoice
} = chatsSlice.actions;
export const getAllChats = (state) => state.chats.chats;
export const getUnreadMessages = (state) => state.chats.unreadMessages;
export default chatsSlice.reducer;
