import React, { useState } from "react";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";
import findValue from "../../helpers/findValue";

const FQA = ({ pageData }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'faq' });
    const getValue = (key) => findValue(pageData?.blocks, key);
    const [showMore, setShowMore] = useState(false);
    const fqaData = getValue('faq_contents_list')?.data || new Array(20).fill(undefined)
        .map((_, index) => ({
            title: t(`faq_${index + 1}_title`),
            description: t(`faq_${index + 1}_descr`)
        }));
    const filteredData = showMore
        ? fqaData
        : fqaData.slice(0, 7);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index
            ? null
            : index);
    };

    return (
        <section className="section faq">
            <div className="container">
                <h2
                    className="heading text-center mb-44"
                >{getValue('faq_title') || t('title')}</h2>

                {filteredData.map((item, index) => (
                    <Accordeon
                        className="w-full"
                        index={index}
                        isActive={activeIndex === index}
                        key={index}
                        onClick={() => toggleAccordion(index)}
                        onlyOneIsOpen={true}
                        title={item.title}
                    >
                        {item?.content || item?.description}
                    </Accordeon>
                )



                )}

                {!showMore && (
                    <Button
                        className="btn btn--primary btn--md show-more__button"
                        onClick={() => setShowMore(true)}
                    >
                        <span className="info">
                            Show more
                        </span>
                    </Button>
                )}
            </div>

        </section>
    );
};

export default FQA;
